import validate from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@20.17.6_eslint@8.57.1_ioredis@5.4.1_magicast@_lqv6246v6qmubtzacezgyis7bu/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/middleware/auth.global.ts";
import company_45details_45global from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/middleware/company-details.global.ts";
import manifest_45route_45rule from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@20.17.6_eslint@8.57.1_ioredis@5.4.1_magicast@_lqv6246v6qmubtzacezgyis7bu/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  company_45details_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  api: () => import("/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/middleware/api.ts")
}