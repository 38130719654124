import type {RouteLocationRaw} from 'vue-router';

export const pageNames = {
	index: 'INDEX',
	login: 'LOGIN',
	logout: 'LOGOUT',
	tocDeclined: 'TOC_DECLINED',
	help: 'HELP',
	inventarisation: 'INVENTARISATION',
	topicDetail: 'TOPIC_DETAIL',
	questionDetail: 'QUESTION_DETAIL',
	riskOverview: 'RISK_OVERVIEW',
	riskDetail: 'RISK_DETAIL',
	riskEdit: 'RISK_EDIT',
	actionOverview: 'ACTION_OVERVIEW',
	actionDetail: 'ACTION_DETAIL',
	actionDetailEdit: 'ACTION_DETAIL_EDIT',
	reportOverview: 'REPORT_OVERVIEW',
	reportCreate: 'REPORT_CREATE',
	companiesOverview: 'COMPANIES_OVERVIEW',
	companyNew: 'COMPANY_NEW',
	companyDetail: 'COMPANY_DETAIL',
	companyDetailEdit: 'COMPANY_DETAIL_EDIT',
	companyUsers: 'COMPANY_USERS',
	companyUsersEdit: 'COMPANY_USERS_EDIT',
	companyUsersInvite: 'COMPANY_USERS_INVITE',
	rieOverview: 'RIE_OVERVIEW',
	rieDetail: 'RIE_DETAIL',
	rieEdit: 'RIE_EDIT',
	rieNew: 'RIE_NEW',
	responsiblesOverview: 'RESPONSIBLES_OVERVIEW',
	responsibleDetail: 'RESPONSIBLE_DETAIL',
	companyDashboard: 'COMPANY_DASHBOARD',
	rieDashboard: 'RIE_DASHBOARD',
	stigasQuestionnaireOverview: 'STIGAS_QUESTIONNAIRE_OVERVIEW',
	stigasQuestionnairePublic: 'STIGAS_QUESTIONNAIRE_PUBLIC'
};

export const companiesOverviewLink = (): RouteLocationRaw => {
	return {
		name: pageNames.companiesOverview
	};
};

export const questionDetailLink = (topicKey: string, questionKey: string, rieId?: string): RouteLocationRaw => {
	return {
		name: pageNames.questionDetail,
		params: {
			topicKey,
			questionKey,
			rieId
		}
	};
};

export const topicOverviewLink = (topicKey: string): RouteLocationRaw => {
	return {
		name: pageNames.topicDetail,
		params: {
			topicKey
		}
	};
};

export const riskDetailLink = (riskId: number): RouteLocationRaw => {
	return {
		name: pageNames.riskDetail,
		params: {
			riskId
		}
	};
};

export const riskEditLink = (riskId: number): RouteLocationRaw => {
	return {
		name: pageNames.riskEdit,
		params: {
			riskId
		}
	};
};

export const riskOverviewLink = (rieId: string): RouteLocationRaw => {
	return {
		name: pageNames.riskOverview,
		params: {
			rieId
		}
	};
};

export const actionDetailLink = (actionId: number, rieId?: string, companyId?: string): RouteLocationRaw => {
	return {
		name: pageNames.actionDetail,
		params: {
			actionId,
			rieId,
			companyId
		}
	};
};

export const actionDetailEditLink = (actionId: number): RouteLocationRaw => {
	return {
		name: pageNames.actionDetailEdit,
		params: {
			actionId
		}
	};
};

export const responsibleDetailLink = (responsibleId: number): RouteLocationRaw => {
	return {
		name: pageNames.responsibleDetail,
		params: {
			id: responsibleId
		}
	};
};

export const rieDashboardLink = (companyId: string, rieId: number): RouteLocationRaw => {
	return {
		name: pageNames.rieDashboard,
		params: {
			companyId,
			rieId
		}
	};
};
export const createReport = (companyId: string, rieId: number): RouteLocationRaw => {
	return {
		name: pageNames.reportCreate,
		params: {
			companyId,
			rieId
		}
	};
};

export const rieDetailLink = (companyId: string, rieId: number): RouteLocationRaw => {
	return {
		name: pageNames.rieDetail,
		params: {
			companyId,
			rieId
		}
	};
};

export const rieOverviewLink = (companyId: string): RouteLocationRaw => {
	return {
		name: pageNames.rieOverview,
		params: {
			companyId
		}
	};
};

export const rieEditLink = (companyId: string, rieId: number): RouteLocationRaw => {
	return {
		name: pageNames.rieEdit,
		params: {
			companyId,
			rieId
		}
	};
};

export const companyRieOverview = (companyId: string): RouteLocationRaw => {
	return {
		name: pageNames.companyDashboard,
		params: {
			companyId
		}
	};
};

export const inviteUserLink = (companyId: string): RouteLocationRaw => {
	return {
		name: pageNames.companyUsersInvite,
		params: {
			companyId
		}
	};
};

export const editUserLink = (companyId: string, userId: string): RouteLocationRaw => {
	return {
		name: pageNames.companyUsersEdit,
		params: {
			companyId,
			userId
		}
	};
};
