<template>
	<NuxtLink
			class="logo"
			to="/"
	>
		<img
				:alt="logoTitle"
				:src="logo.url"
				:width="logo.width"
				:height="logo.height"
				loading="lazy"
		/>
	</NuxtLink>
</template>

<script lang="ts" setup>
	import {useRuntimeConfig} from '#app';
	import {computed} from 'vue';

	import logoStigas from '~/assets/images/logo-stigas.png';
	import logoLuminis from '~/assets/images/logo-luminis.svg';

	const config = useRuntimeConfig();

	const logoTitle = config.public.brancheName;

	const logo = computed<{ url: string, width: number, height: number }>(() => {
		if (config.public.logo === 'logo-stigas.png') {
			return {
				url: logoStigas,
				width: 121,
				height: 121
			};
		}
		return {
			url: logoLuminis,
			width: 192,
			height: 36
		};
	});
</script>

<style scoped>
	.logo {
		display: grid;
		place-items: center;
		align-items: center;
		min-height: 135px;
		margin: 0;
		padding: 12px 24px;
	}

	img {
		vertical-align: middle;

		object-fit: contain;

		width: auto;
		height: auto;

		max-width: 100%;
		max-height: 121px;
	}
</style>
