import {acceptHMRUpdate, defineStore} from 'pinia';
import {ref} from 'vue';
import useApi from '~/composables/useApi';
import type {ProblemDetail} from '~/lib/types/fetchErrors';
import type {CompanyOverviewDecorator} from '~/lib/types/companyTypes';

export const useCompaniesStore = defineStore('companies', () => {
	const {GET} = useApi();

	const loading = ref(false);
	const companies = ref<CompanyOverviewDecorator[]>([]);
	const problemDetail = ref<ProblemDetail>();

	const fetchCompanies = async () => {
		loading.value = true;
		problemDetail.value = undefined;
		companies.value = [];

		const {data, error} = await GET(`/companies`);

		if (error) {
			problemDetail.value = error;
		}

		if (error || !data) {
			loading.value = false;
			return false;
		}

		companies.value = data;
		loading.value = false;
		return true;
	};

	return {
		companies,
		fetchCompanies,
		loading,
		problemDetail
	};
});

if (import.meta.hot) {
	import.meta.hot.accept(acceptHMRUpdate(useCompaniesStore, import.meta.hot));
}
