import revive_payload_client_GlDopvR8em from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@20.17.6_eslint@8.57.1_ioredis@5.4.1_magicast@_lqv6246v6qmubtzacezgyis7bu/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_UqjnEYTSWR from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@20.17.6_eslint@8.57.1_ioredis@5.4.1_magicast@_lqv6246v6qmubtzacezgyis7bu/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_5VogxxcPzn from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@20.17.6_eslint@8.57.1_ioredis@5.4.1_magicast@_lqv6246v6qmubtzacezgyis7bu/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_bPSAnGRlmj from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@20.17.6_eslint@8.57.1_ioredis@5.4.1_magicast@_lqv6246v6qmubtzacezgyis7bu/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_5BIKiwKp2Z from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@20.17.6_eslint@8.57.1_ioredis@5.4.1_magicast@_lqv6246v6qmubtzacezgyis7bu/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_4zpW4Kk52z from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@20.17.6_eslint@8.57.1_ioredis@5.4.1_magicast@_lqv6246v6qmubtzacezgyis7bu/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_b5QJA0cCPf from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@20.17.6_eslint@8.57.1_ioredis@5.4.1_magicast@_lqv6246v6qmubtzacezgyis7bu/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_q6WeiPRc3E from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.25.0_typescript@5.6.3_vue@3.5.12_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_BS0P4fsRs2 from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@20.17.6_eslint@8.57.1_ioredis@5.4.1_magicast@_lqv6246v6qmubtzacezgyis7bu/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import customElements_client_71mjTDa34z from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/plugins/customElements.client.ts";
import sentry_client_shVUlIjFLk from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/plugins/sentry.client.ts";
export default [
  revive_payload_client_GlDopvR8em,
  unhead_UqjnEYTSWR,
  router_5VogxxcPzn,
  payload_client_bPSAnGRlmj,
  navigation_repaint_client_5BIKiwKp2Z,
  check_outdated_build_client_4zpW4Kk52z,
  chunk_reload_client_b5QJA0cCPf,
  plugin_vue3_q6WeiPRc3E,
  components_plugin_KR1HBZs4kY,
  prefetch_client_BS0P4fsRs2,
  customElements_client_71mjTDa34z,
  sentry_client_shVUlIjFLk
]