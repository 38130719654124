import createClient from 'openapi-fetch';
import {computed} from 'vue';
import type {paths} from '~/lib/types/openapi';
import {useAuthStore} from '~/store/global/auth';
import {authMiddleware, errorHandlerMiddleware} from '~/middleware/api';

// Relative URL's don't work in node (while running tests in vitest).
export const apiBaseUrl = import.meta.env.MODE === 'test' ? 'https://localhost' : '';

export default <openapiPaths extends {} = paths>() => {
	const authStore = useAuthStore();
	const authorizationHeader = computed(() => {
		if (!authStore.token) {
			return null;
		}
		return `Bearer ${authStore.token}`;
	});

	const client = () => {
		const client = createClient<openapiPaths>({
			baseUrl: `${apiBaseUrl}/api/`
		});

		client.use(authMiddleware(authorizationHeader));
		client.use(errorHandlerMiddleware());

		return client;
	};

	const upload = (formData: FormData) => {
		const headers = {} as Record<string, string>;
		if (authorizationHeader.value) {
			headers.Authorization = authorizationHeader.value;
		}
		return fetch('/api/upload', {
			method: 'POST',
			body: formData,
			headers
		});
	};

	const GET = client().GET;
	const PATCH = client().PATCH;
	const POST = client().POST;
	const PUT = client().PUT;
	const DELETE = client().DELETE;

	return {
		upload,
		GET,
		PATCH,
		POST,
		PUT,
		DELETE
	};
};
